<template lang="pug">
.managers-container-desktop
  breadcrumbs(
    :items="breadcrumbItems"
  )

  card
    .filtering-container
      h2.title {{ $t('.title') }}

      button.icon.button-blue(@click="showNewBillModal = true")
        i.fa.fa-plus

    .table-section
      .table-container(@scroll="autoLoad")
        table
          thead
            tr
              th(v-for="name in columnNames", :key="name")
                p  {{ $t(`.table.${name}`) }}
          tbody
            tr(v-for="(manager, index) in managers", :key="index")
              td {{ manager.name }}
              td {{ manager.email }}
              td {{ isLocked(manager) ? $t('yes') : $t('no') }}
              td
                edit-manager-modal(
                  :name="manager.name",
                  :email="manager.email",
                  :blocked="isLocked(manager)",
                  :id="manager.id",
                  @update="updateManager"
                )
            tr.loading(v-if="loading")
              td(v-for="cell in cellsLoader" :key='cell')
                .cell
      footer
        | {{ $t('.footer.text', { length: managers.length, total: pagination.total }) }}

  create-manager-modal(@update="addManager", :show="showNewBillModal")
</template>
<script>
import objects from "lib/objects"
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import EditManagerModal from "./managers/edit-modal.vue"
import CreateManagerModal from "./managers/create-modal.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: { EditManagerModal, CreateManagerModal },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.team.managersPage",

      prefixUrlAgency: this.$user.prefixUrlAgency,

      // data
      managers: [],

      pagination: { total: 0 },
      fetchInterval: null,
      loading: false,
      submitting: false,
      errors: {},
      hasError: false,
      showNewBillModal: false
    }
  },

  created() {
    this.loading = true

    this.fetch()
  },

  computed: {
    columnNames() {
      return ['name', 'email', 'blocked', 'action']
    },

    btnSubmitText() {
      if (this.submitting) {
        return this.$t(".newManager.submitting")
      }

      return this.$t(".newManager.submit")
    },

    breadcrumbItems() {
      return [
        { name: this.$t('.breadcrumbs.managers'), url: this.routePath }
      ]
    },

    cellsLoader() {
      return document.getElementsByTagName('th')
    }
  },

  methods: {
    init({ managers, pagination }) {
      this.managers = managers.map((report) => objects.camelize(report))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    parseRoute() {
      let params = {}

      Object.assign(
        params,
        this.$params.parse(this.$route.query, {
          name: "string",
          cpf: "string",
          real_estate_manager: "string",

          // paginação e ordenação
          page: "integer",

          sort: "string",
          order: "string",
        })
      )

      this.params = params
    },

    fetch() {
      if (this.initialized) {
        this.$router.replace({ name: this.routeName, query: this.httpParams })
      }

      return this.$http
        .get(`/${this.prefixUrlAgency}/managers.json`, { params: this.httpParams })
        .then((response) => {
          const body = response.data
          let managers = body.data
          let pagination = body.pagination || {}

          this.init({ managers, pagination })
          this.loading = false
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
          this.loading = false
        })
    },

    isLocked(manager) {
      return manager?.lockedAgencies?.includes(this.$agency.get.id)
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target

      if ((scrollTop + clientHeight < scrollHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    addManager(manager) {
      this.managers.unshift(manager)
      this.pagination.total++
    },

    updateManager(manager) {
      this.managers = this.managers.map(item => {
        if (item.id == manager.id) {
          return objects.camelize(manager)
        }

        return item
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/base/tables.scss";

#page {
  overflow: hidden;
}

.managers-container-desktop {
  .table-section {
    margin-top: 1.5rem;
  }

  .filtering-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }

    .button-blue {
      margin: 0;
      padding: 0 1.5rem;
    }

    .actions {
      display: flex;

      button.opened {
        opacity: 0.5;
      }

      button.icon {
        font-size: 1.5rem;
        width: 4rem;
        padding: 0;
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }

  .menu-container {
    width: 25%;
    height: 100vh;
    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .create-button {
      margin: 0rem;
      width: 100%;
    }
  }

  .show-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    padding-bottom: 10rem;
    overflow-y: auto;

    .managers-container {
      background-color: $white-color;
      padding: 2.5rem;
      border-radius: 0.5rem;
      box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

      h2 {
        font-size: 2rem;
        font-weight: 500;
        color: $primary-color;
      }

    }
  }
}
</style>
