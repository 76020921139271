<template lang="pug">
.landlord-section
  .inline-section
    .four-columns
      h3.info-title {{ attributeTranslate('landlordName') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        name="landlord[name]"
        :value="contract.landlordAttributes.name"
        :path="landlordPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.landlordAttributes.name || '-' }}

    .four-columns
      h3.info-title {{ attributeTranslate('landlordCpfCnpj') }}

      .loading-content(v-if="loading")

      editable-cpf-cnpj-field(
        v-else-if="contract.editable"
        name="landlord[cpf_cnpj]"
        v-model="contract.landlordAttributes.cpfCnpj"
        :path="landlordPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.landlordAttributes.cpfCnpj || '-' }}

    .four-columns(v-if="transferToLandlord")
      h3.info-title {{ attributeTranslate('landlordBankAccountAgency') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        name="landlord[bank_account_attributes][agency]"
        :value="contract.landlordAttributes.agency"
        :path="landlordPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.landlordAttributes.agency || '-' }}

  .inline-section(v-if="transferToLandlord")
    .four-columns
      h3.info-title {{ attributeTranslate('landlordBankAccountAccount') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        name="landlord[bank_account_attributes][account]"
        :value="contract.landlordAttributes.account"
        :path="landlordPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.landlordAttributes.account || '-' }}

    .four-columns
      h3.info-title {{ attributeTranslate('landlordBankAccountCode') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        name="landlord[bank_account_attributes][code]"
        :value="contract.landlordAttributes.code"
        :path="landlordPath"
        :callback="onUpdate"
      )

      p.info-content(v-else) {{ contract.landlordAttributes.code || '-' }}

    .four-columns
      h3.info-title {{ attributeTranslate('landlordBankAccountPix') }}

      .loading-content(v-if="loading")

      editable-input-field(
        v-else-if="contract.editable"
        name="landlord[bank_account_attributes][pix]"
        :value="!!contract.landlordAttributes.pix ? contract.landlordAttributes.pix : null"
        :path="landlordPath"
        :callback="onUpdate"
      )

      p.info-content(v-else)
        | {{ !!contract.landlordAttributes.pix ? contract.landlordAttributes.pix : '-' }}
</template>

<script>
import EditableInputField from "../../../../../../components/desktop/editable-fields/editable-input-field.vue"
import EditableCpfCnpjField from "../../../../../../components/desktop/editable-fields/editable-cpf-cnpj-field.vue"

export default {
  components: { EditableInputField, EditableCpfCnpjField },

  props: {
    contract: { type: Object, required: true },
    contractPath: { type: String, required: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, default: () => {} }
  },

  computed: {
    landlordPath() {
      return `${this.contractPath}/landlord`
    },

    transferToLandlord() {
      return this.contract.transferTo === 'landlord'
    }
  },

  methods: {
    attributeTranslate(path) {
      return this.$t(`models.contract.attributes.${path}`)
    }
  }
}
</script>